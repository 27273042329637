import React, { Component } from 'react'
import { reduxForm, change } from 'redux-form'
import validate from '../helpers/validate'
import Address from './Address';
import { receiverValidate } from '../helpers/addressValidate';
import GlobalAlert from './GlobalAlert';
import PropTypes from 'prop-types';
import WizardButtonBar from './WizardButtonBar';
import { connect } from 'react-redux';

class Receiver extends Component {
  constructor(props) {
    super(props);
    this.getCountries = this.getCountries.bind(this);
    this.state = {
      loading: false
    }
  }

  editAddress = () => {
    this.props.setReceiverId('');
  }

  componentDidMount() {
    this.setState({
      loading: false
    })
  }

  getCountries() {
    return this.props.countries && this.props.countries.length ? this.props.countries : [
      {
        "country_code": "US",
        "currency_code": "USD,USN,USS",
        "display_name": "United States 🇺🇸",
        "flag": "🇺🇸",
        "name": "United States"
      },
      {
        "country_code": "AU",
        "currency_code": "AUD",
        "display_name": "Australia 🇦🇺",
        "flag": "🇦🇺",
        "name": "Australia"
      }
    ];
  }

  isLoading = () => {
    return this.state.loading && !this.props.submitFailed;
  };

  handleSubmit = event => {
    // Set loading to true
    this.setState({
      loading: true
    });

    return this.props.handleSubmit(event);
  };

  render() {
    const saved = this.props.receiverAddressId.length > 0;
    return (
      <React.Fragment>
        <div className="row">
          <GlobalAlert error={this.props.error} show={this.props.submitFailed} />
        </div>
        <div className="row">
          <div className="col-12 col-md-6 address-copy">
            <h2>Fill in their details{saved && ": (Saved)"}</h2>
            <p className="half-width">Enter recipient postal details</p>
          </div>
          <div className="col-12 col-md-6">
            <form onSubmit={this.handleSubmit} className={this.isLoading() ? 'loading' : ''}>
              <Address
                countries={this.getCountries}
                type="receiver"
                title="To:"
                saved={saved}
                editAddress={this.editAddress}
                savedAddress={this.props.savedAddress}
                />
              <WizardButtonBar
                hasPrevious={true}
                previousClick={this.props.previousPage}
                previousLoading={this.props.previousPage}
                nextLoading={this.props.submitting}
              />
            </form>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

Receiver.propTypes = {
  handleSubmit: PropTypes.func,
  countries: PropTypes.array,
  change: PropTypes.func,
  error: PropTypes.string,
  submitFailed: PropTypes.bool,
  submitting: PropTypes.bool,
  previousPage: PropTypes.func,
  dispatch: PropTypes.func,
  savedAddress: PropTypes.object,
  setReceiverId: PropTypes.func,
  receiverAddressId: PropTypes.string
};

const mapStateToProps = state => {
  return {
    message_id: state.form.wizard.values.message_id,
    receiverAddressId: state.customReducer.receiver_address_id,
    savedAddress: {
      name: state.form.wizard.values.receiver_name,
      address_line1: state.form.wizard.values.receiver_address_line1,
      address_line2: state.form.wizard.values.receiver_address_line2,
      state: state.form.wizard.values.receiver_state,
      city: state.form.wizard.values.receiver_city,
      country: state.customReducer.receiver_country,
      zip_code: state.form.wizard.values.receiver_zip,
    }
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
      setReceiverId: value => {
        dispatch({
              type: "SET_RECEIVER_ADDRESS_ID",
              payload: value
        });
        dispatch(change('wizard', 'receiver_address_id', value));
      }
  };
};

export default reduxForm({
  form: 'wizard', // <------ same form name
  destroyOnUnmount: false, // <------ preserve form data
  forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
  validate,
  asyncValidate: receiverValidate,
  asyncBlurFields: []
})(connect(mapStateToProps, mapDispatchToProps)(Receiver));
