import React, { Component } from 'react';
import PropTypes from 'prop-types';
import CostModal from './CostModal';

class LandingBottom extends Component {
  constructor(props) {
    super(props);

    this.state = {
      openModal: false,
    }
  }

  toggleModal = () => {
    this.setState({
      openModal: !this.state.openModal
    });
  }

  openModal = (event) => {
    event.preventDefault();
    this.setState({
      openModal: true
    })
  }

  render () {
    return (
        <React.Fragment>
            <p className="copy-well">Surprise your friends by sending them real emojis through the mail!</p>
            <button onClick={this.props.onSubmit} className={"btn btn-secondary next" + (this.props.countriesLoading ? " loading": "")}>
              <div className="lds lds-ellipsis"><div></div><div></div><div></div><div></div></div>
              Start
            </button>
            
            {!this.props.countriesLoading &&
              <p className="price-copy">
                {this.props.country.flag} {this.props.country.currency_symbol}{this.props.country.price} ({this.props.country.currency_code})
                <span className="divider">
                  <span>&nbsp;|&nbsp;</span>
                </span>
                <a href="#" onClick={this.openModal}>🌏 Other Countries</a>
              </p>
            }
            {!this.props.countriesLoading && 
              <CostModal
                openModal={this.state.openModal}
                toggleModal={this.toggleModal}
                countries={this.props.countries}
                country={this.props.country}
                countryDetected={this.props.countryDetected}
              />
            }
        </React.Fragment>
    );
  }
}

LandingBottom.propTypes = {
  onSubmit: PropTypes.func,
  country: PropTypes.object,
  countries: PropTypes.array,
  countryDetected: PropTypes.bool,
  countriesLoading: PropTypes.bool
};

export default LandingBottom;