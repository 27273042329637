function validateAddress(values, type, errors) {
  if (!values[`${type}_name`]) {
    errors[`${type}_name`] = 'No name supplied';
  }

  if (!values[`${type}_address_line1`]) {
    errors[`${type}_address_line1`] = 'No address supplied';
  }

  if (!values[`${type}_city`]) {
    errors[`${type}_city`] = 'No city supplied';
  }

  if (!values[`${type}_country`]) {
    errors[`${type}_country`] = 'No country supplied';
  }
}

const validate = values => {
  const errors = {receiver: {}, sender: {}, message: ""};
  
  if (!values.message || !values.message.length) {
    errors._error = 'Message cannot be empty. Add some Emojis!';
    return errors;
  }

  if (!values.email) {
    errors.email = 'Email required';
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = 'Invalid email address';
  }

  validateAddress(values, "receiver", errors);
  validateAddress(values, "sender", errors)

  return errors;
};

export default validate;
