import { createStore, combineReducers } from 'redux';
import { reducer as reduxFormReducer } from 'redux-form';
import customReducer from './customReducer';

const reducer = combineReducers({
  form: reduxFormReducer, // mounted under "form"
  customReducer: customReducer
});

const store = (window.__REDUX_DEVTOOLS_EXTENSION__
  ? window.__REDUX_DEVTOOLS_EXTENSION__()(createStore)
  : createStore)(reducer);

export default store;
