const customReducer = function(state = {
    message_id: "",
    receiver_address_id: "",
    sender_address_id: "",
    message: [],
    frontContent: "",
    backContent: "",
    receiver_country: "",
    sender_country: "",
    SET_CHECKOUT_PRICE: ""
}, action) {
    switch (action.type) {
        case 'SET_RECEIVER_ADDRESS_ID' : {
            return {...state,
                receiver_address_id: action.payload
            }
        }
        case "SET_SENDER_ADDRESS_ID" : {
            return {...state,
                sender_address_id: action.payload
            }
        }
        case "SET_PAYMENT_NONCE": {
            return {
                ...state,
                nonce: action.payload
            }
        }
        case "SET_MESSAGE_ID": {
            return {
                ...state,
                message_id: action.payload
            }
        }
        case "SET_MESSAGE":
            return {
                ...state,
                message: action.payload
            }
        case "SET_FRONT_CONTENT":
            return {
                ...state,
                frontContent: action.payload
            }
        case "SET_BACK_CONTENT":
            return {
                ...state,
                backContent: action.payload
            }
        case "SET_RECEIVER_COUNTRY":
            return {
                ...state,
                receiver_country: action.payload
            }
        case "SET_SENDER_COUNTRY":
            return {
                ...state,
                sender_country: action.payload
            }
        case "SET_CHECKOUT_PRICE":
            return {
                ...state,
                checkoutPrice: action.payload
            }
        default:{
            return state
        }
    }
};

export default customReducer;
