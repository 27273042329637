import React from 'react';
import PropTypes from 'prop-types';

const renderField = ({ input, name, className="", label, type, meta: { touched, error } }) => (
  <div className={"form-group " + className}>
    <label htmlFor={name} className="sr-only">{label}</label>
    <input {...input} placeholder={label} type={type} className="form-control" />
    {touched && error && <div className="invalid-feedback" style={{display: "block"}}>{error}</div>}
  </div>
)

renderField.propTypes = {
  input: PropTypes.object,
  label: PropTypes.string,
  textField: PropTypes.string,
  meta: PropTypes.object,
  type: PropTypes.string,
  name: PropTypes.string,
  className: PropTypes.string
};

export default renderField