import React, { Component } from 'react';
import PropTypes from 'prop-types';
import AboutModal from './AboutModal';
import ContactModal from './ContactModal';

class Footer extends Component {
  constructor(props) {
    super(props);

    this.state = {
        openAboutModal: false,
        openContactModal: false
    }
  }

  goToContact = (event) => {
    event.preventDefault();
    this.setState({
        openAboutModal: false,
        openContactModal: true,
    });
}

toggleAboutModal = () => {
    this.setState({
        openAboutModal: !this.state.openAboutModal
    });
}

openAboutModal = (event) => {
    event.preventDefault();
    this.setState({
        openAboutModal: true
    })
}

toggleContactModal = () => {
    this.setState({
        openContactModal: !this.state.openContactModal
    });
}

openContactModal = (event) => {
    event.preventDefault();
    this.setState({
        openContactModal: true
    })
}

  render () {
    return (
        <footer>
            <div className="container">
                <div className="hr"></div>
                <div className="about-links">
                    <a href="#" onClick={this.openAboutModal}>About</a> | <a href="#" onClick={this.openContactModal}>Contact</a> | <a href="https://www.instagram.com/emoji_mailer/"><img src="/social-media/instagram.svg" alt="Instagram" /></a> | <a href="https://www.facebook.com/emojimailer/"><img src="/social-media/facebook.svg" className="pad" alt="Facebook" /></a> | <a href="https://twitter.com/EmojiMailer"><img src="/social-media/twitter.svg" alt="Twitter" className="pad" /></a>
                </div>
            </div>
            <AboutModal
                openModal={this.state.openAboutModal}
                toggleModal={this.toggleAboutModal}
                countries={this.props.countries}
                goToContact={this.goToContact}
                countriesLoading={this.props.countriesLoading}
            />
            <ContactModal
                openModal={this.state.openContactModal}
                toggleModal={this.toggleContactModal}
            />
        </footer>
    );
  }
}

Footer.propTypes = {
    countries: PropTypes.array,
    countriesLoading: PropTypes.bool
};

export default Footer;