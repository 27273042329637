import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';

class Success extends Component {
  constructor(props) {
    super(props);
  }

  resetForm = () => {
    const fieldsToReset = [
      'receiver_address_id',
      'receiver_name',
      'receiver_address_line1',
      'receiver_address_line2',
      'receiver_state',
      'receiver_city',
      'receiver_country',
      'receiver_zip',
      'message'
    ];
    fieldsToReset.forEach(field => this.props.resetSection(field));
    this.props.resetCustomReducer();
    this.props.onSubmit();
  };

  render () {
    return (
        <React.Fragment>
          <div className="content full-page success-page">
            <h2>Congratulations!</h2>
            <p>Your EmojiMailer has been sent and an email with delivery details has been sent to your email.</p>
            <Button className="btn btn-primary" onClick={this.resetForm}>Send another</Button>
          </div>
        </React.Fragment>
    );
  }
}

Success.propTypes = {
  onSubmit: PropTypes.func,
  resetForm: PropTypes.func,
  resetSection: PropTypes.func,
  resetCustomReducer: PropTypes.func
};

const mapDispatchToProps = (dispatch) => {
  return {
    resetCustomReducer: () => {
      dispatch({
        type: "SET_RECEIVER_ADDRESS_ID",
        payload: ''
      });

      dispatch({
        type: 'SET_MESSAGE_ID',
        payload: ''
      });

      dispatch({
        type: 'SET_MESSAGE',
        payload: []
      });
    }
  }
};

export default reduxForm({
  form: 'wizard', // <------ same form name
  destroyOnUnmount: false, // <------ preserve form data
  forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
  asyncBlurFields: []
})(connect(undefined, mapDispatchToProps)(Success));
