import {getFormData} from './form';

const addressValidate = (values, dispatch, type) => {
    const id = values[type === "sender" ? 'sender_address_id' : 'receiver_address_id'];
    // Check if address already has an ID
    if (id) {
        return new Promise(resolve => {
            resolve();
        });
    }

    const address = {
        name: values[`${type}_name`] ? values[`${type}_name`] : "",
        address_line1: values[`${type}_address_line1`] ? values[`${type}_address_line1`] : "",
        address_line2: values[`${type}_address_line2`] ? values[`${type}_address_line2`] : "",
        address_city: values[`${type}_city`] ? values[`${type}_city`] : "",
        address_state: values[`${type}_state`] ? values[`${type}_state`] : "",
        address_country: values[`${type}_country`],
        address_zip: values[`${type}_zip`] ? values[`${type}_zip`] : "",
        message_id: values[`message_id`]
    };
    
    if (type === "receiver") {
        address.message = values['message'].join(',');
    } else {
        address.message_id = values['message_id'];
        address.email = values['email'];
    }
    
    const endpoint = type === "receiver" ? '/verify_receiver' : '/verify_sender';
    const url = `${location.protocol}//${location.host}${endpoint}`;
    
    const fetchCall = fetch(url, {
        method: 'post',
        headers: {
            'X-CSRF-Token': document.querySelector('[name="csrf-token"]').content
        },
        body: getFormData(address),
    });

    return new Promise((resolve, reject) => {
        fetchCall.then( response => {
            if (response.ok === false) {
                if (response.status === 422) {
                    return response.json()
                } else {
                    reject({ all: "Cannot contact remote service" })
                }
            } else {
                return response.json();
            }
        }, () => {
            reject({ all: "Cannot contact remote service" })
        }).then(json => {
            if (json && json.id) {
                dispatch({
                    type: type === "receiver" ? "SET_RECEIVER_ADDRESS_ID" : "SET_SENDER_ADDRESS_ID",
                    payload: json.id
                });

                dispatch({
                    type: type === "receiver" ? "SET_RECEIVER_COUNTRY" : "SET_SENDER_COUNTRY",
                    payload: json.country
                });

                if (type === "receiver"){
                    dispatch({
                        type: "SET_CHECKOUT_PRICE",
                        payload: json.price
                    }); 
                }

                values[`${type}_address_id`] = json.id;
                resolve();
            } else if (json && json.field) {
                const error = {};
                if (json.field !== "email") {
                    error[`${type}_${json.field}`] = json.message;
                } else {
                    error[json.field] = json.message;
                }
                
                reject(error);
            } else {
                reject();
            }
        });
    });
}

const senderValidate = (values, dispatch) => {
    return addressValidate(values, dispatch, "sender");
};

const receiverValidate = (values, dispatch) => {
    return addressValidate(values, dispatch, "receiver");
};

export {
    senderValidate,
    receiverValidate
};
