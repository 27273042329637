import React, { Component } from 'react';
import Alert from 'react-bootstrap/Alert';
import { ReCaptcha } from 'react-recaptcha-v3';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import PropTypes from 'prop-types';

import GlobalAlert from './GlobalAlert';
import { getFormData } from '../helpers/form';
import { GOOGLE_RECAPTCHA } from '../helpers/keys';

class ContactModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: props.from ? props.from : '',
            email: '',
            message: props.context ? props.context : '',
            showError: false,
            errorMessage: '',
            sendSuccess: false,
            recaptchaToken: ''
        };
    }

    changeField = (field, event) => {
        const newState = {
            showError: false,
            sendSuccess: false,
            loading: false
        };
        newState[field] = event.target.value;

        this.setState(newState);
    }

    hasRecaptcha() {
        return location.hostname !== "localhost";
    }

    nameChange = (event) => {
        this.changeField('name', event);
    };

    emailChange = (event) => {
        this.changeField('email', event);
    };

    messageChange = event => {
        this.changeField('message', event);
    };

    verifyCallback = (recaptchaToken) => {
        this.setState({
            recaptchaToken
        });
    };

    closeModal = () => {
        if (this.state.sendSuccess && !this.props.message_id) {
            this.setState({
                name: "",
                email: "",
                message: ""
            });
        }
        this.setState({
            showError: false,
            sendSuccess: false
        });
        
        this.props.toggleModal();
    };

    render() {
        return (
            <Modal isOpen={this.props.openModal} toggle={this.closeModal}>
                <ModalHeader toggle={this.props.toggleModal}>📞 Contact</ModalHeader>
                <form className="contact-form" onSubmit={this.sendMessage}>
                    <ModalBody>
                        <p>Reach out to us on social media!</p>
                        <ul className="social-media-links">
                            <li><a href="https://www.instagram.com/emoji_mailer/"><img src="/social-media/instagram.svg" alt="Instagram" />Instagram</a></li>
                            <li><a href="https://www.facebook.com/emojimailer/"><img src="/social-media/facebook.svg" alt="Facebook" /> Facebook</a></li>
                            <li><a href="https://twitter.com/EmojiMailer"><img src="/social-media/twitter.svg" alt="Twitter" /> Twitter</a></li>
                        </ul>
                        <p><strong>Or... Contact us below!</strong></p>
                        <GlobalAlert show={this.state.showError} error={this.state.errorMessage} />
                        <Alert variant='success' show={this.state.sendSuccess}>
                            👍 Message has been sent! You should hear back soon
                        </Alert>
                        { !this.props.message_id &&
                            <React.Fragment>
                                <div className="form-group">
                                    <label htmlFor="name" className="sr-only">Name</label>
                                    <input type="text" className="form-control" id="name" placeholder="Name" required value={this.state.name} onChange={this.nameChange}/>
                                </div>
                                <div className={`form-group`}>
                                    <label htmlFor="email" className="sr-only">Email</label>
                                    <input type="email"  name="email" className="form-control" id="email" aria-describedby="emailHelp" placeholder="Email" required value={this.state.email}  onChange={this.emailChange}/>
                                    <small id="emailHelp" className="form-text text-muted ml-3">😶 We will never share your email with anyone else</small>
                                </div>
                            </React.Fragment>
                        }
                        <div className="form-group">
                            <label htmlFor="message" className="sr-only">Message</label>
                            <textarea className="form-control" id="message" name="message" rows="3" placeholder="Message" required value={this.state.message}  onChange={this.messageChange}></textarea>
                        </div>
                        {this.hasRecaptcha() &&
                            <ReCaptcha
                                ref={ref => this.recaptcha = ref}
                                sitekey={GOOGLE_RECAPTCHA}
                                size="normal"
                                render="explicit"
                                verifyCallback={this.verifyCallback}
                            />
                        }
                    </ModalBody>
                    <ModalFooter>
                        { !this.state.sendSuccess && 
                            <button className="btn btn-primary" type="submit">Send</button>
                        }
                        { this.state.sendSuccess &&
                            <button className="btn btn-primary" onClick={this.closeModal}>Done</button>
                        }
                    </ModalFooter>
                </form>
            </Modal>
        )
    }

    sendMessage = (event) => {
        event.preventDefault();

        this.setState({
            sendSuccess: false,
            showError: false,
            loading: true
        });

        const hasMessage = this.props.message_id && this.props.message_id.length > 0;

        if (!hasMessage) {
            if (!this.state.name) {
                this.setState({
                    showError: true,
                    errorMessage: "Please enter a name",
                    loading: false
                })
                return;
            }

            if (!hasMessage && !this.state.email) {
                this.setState({
                    showError: true,
                    errorMessage: "Please enter in an email",
                    loading: false
                })
                return;
            }
    
            if (!hasMessage && !this.state.email.match(/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/)) {
                this.setState({
                    showError: true,
                    errorMessage: "Please enter in a valid email",
                    loading: false
                })
                return;
            }
        }

        if (this.hasRecaptcha() && !this.state.recaptchaToken) {
            this.setState({
                showError: true,
                errorMessage: "Please fill in ReCaptcha",
                loading: false
            })
            return;
        }

        if (!this.state.message) {
            this.setState({
                showError: true,
                errorMessage: "Please enter a message",
                loading: false
            })
            return;
        }

        const payload = {
            name: this.state.name,
            email: this.state.email,
            message: this.state.message
        };

        if (this.props.message_id) {
            payload.message_id = this.props.message_id;
        }

        if (this.hasRecaptcha()) {
            payload.recaptchaToken = this.state.recaptchaToken;
        }

        const genericServerError = "Error sending message, please use one of the above social networks or try again later";
        fetch(`/contact`, {
            method: 'post',
            headers: {
                'X-CSRF-Token': document.querySelector('[name="csrf-token"]').content
            },
            body: getFormData(payload),
        }).then((response) => {
            // If succeeds or the error is understood
            if (response.ok || response.status == 400) {
                return response.json();
            } else {
                this.setState({
                    showError: true,
                    errorMessage: genericServerError,
                    loading: false
                });
            }
        }, () => {
            this.setState({
                showError: true,
                errorMessage: genericServerError,
                loading: false
            });
        }).then((json) => {
            if (typeof json !== "undefined") {
                if (json.message) {
                    this.setState({
                        showError: false,
                        sendSuccess: true,
                        loading: false
                    });
                    return;
                } else if (json.error) {
                    this.setState({
                        showError: true,
                        sendSuccess: false,
                        errorMessage: json.error,
                        loading: false
                    });
                    return;
                }
            }
            
            this.setState({
                showError: true,
                errorMessage: genericServerError,
                loading: false
            });
        });
        
        if (this.hasRecaptcha()){
            // Reset recaptcha token so it can be used again
            this.recaptcha.execute();
        }
    };
}

ContactModal.propTypes = {
    toggleModal: PropTypes.func,
    openModal: PropTypes.bool,
    context: PropTypes.string,
    from: PropTypes.string,
    name: PropTypes.string,
    message_id: PropTypes.string
};

export default ContactModal;