import React, { Component } from 'react';

export default class LandingTop extends Component {
    render() {
        return (
            <React.Fragment>
                <div className="container">
                    <div className="content landing-page">
                        <img className="img-fluid landing"
                            src="/assets/landing.png"
                        />
                        <img className="img-fluid logo"
                            src="/assets/logo.png"
                        />
                    </div>
                </div>
            </React.Fragment>
        );
    }
}