import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Picker } from 'mr-emoji';
import { ReCaptcha } from 'react-recaptcha-v3';

import GlobalAlert from './GlobalAlert';
import PropTypes from 'prop-types';
import WizardButtonBar from './WizardButtonBar';
import { reduxForm } from 'redux-form';
import validate from '../helpers/validate';
import { GOOGLE_RECAPTCHA } from '../helpers/keys';
import { getFormData } from '../helpers/form';

const MAXIMUM_CHARACTERS = 12;
const TIME_TO_TYPE = 500;

class EmojiInput extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      openModal: false,
      emojiContent: "",
      exclude: ['recent', 'custom'],
      errorMessage: '',
      errorShow: false,
      recaptchaToken: ''
    }

    this.fullMessage = ['1f600', '2709-fe0f', '1f602'];
    this.checkMessage = this.checkMessage.bind(this);
    this.demo();
  }

  demo = () => {
    // Ensure demo hasn't been run before
    if (this.props.frontContent.length === 0) {
      this.typeUp(0);
    }
  };

  typeUp = index => {
    // Check there is no typing already
    if (this.props.message.length === 0) {
      setTimeout(() => {
        if (this.fullMessage.length === index) {
          this.typeDown(this.fullMessage.length);
        } else {
          this.getFrontContent(this.fullMessage.slice(0, index + 1));
          this.typeUp(index + 1);
        }
      }, TIME_TO_TYPE);
    }
  }

  typeDown = index => {
    // Check there is no typing already
    if (this.props.message.length === 0) {
      setTimeout(() => {
        if (index > 0) {
          this.getFrontContent(this.fullMessage.slice(0, index - 1));
          this.typeDown(index - 1)
        }
      }, TIME_TO_TYPE);
    }
  };

  checkMessage() {
    this.setState({
      errorShow: false,
      errorMessage: ''
    });

    if (this.hasRecaptcha() && !this.state.recaptchaToken) {
      this.setState({
        errorShow: true,
        errorMessage: 'Please fill in ReCaptcha'
      });

      return;
    }
    
    if (this.props.message.length === 0) {
      this.setState({
        errorShow: true,
        errorMessage: 'Message cannot be empty. Add some Emojis!'
      });

      return;
    }

    this.setState({
      loading: true
    });

    const payload = {
      message: this.props.message.join(',')
    };

    if (location.hostname !== "localhost") {
      payload.recaptchaToken = this.state.recaptchaToken;
    }

    if (this.props.message_id) {
      payload.message_id = this.props.message_id;
    }

    fetch('/set_message', {
      method: 'post',
      headers: {
        'X-CSRF-Token': document.querySelector('[name="csrf-token"]').content
      },
      body: getFormData(payload)
    }).then(response => {
      return response.json();
    }, error => {
      console.error(error);
      this.setState({
        loading: false,
        errorShow: true,
        errorMessage: 'Cannot connect to server, please try again later! 🙇🙇🙇'
      });
    }).then(json => {
      if (json.message_id) {
        this.props.change('message_id', json.message_id);
        
        this.setState({
          loading: false,
          errorShow: false
        });

        this.props.onSubmit();
      } else if (json.error) {
        this.setState({
          loading: false,
          errorShow: true,
          errorMessage: json.error
        });
      }
    }, error => {
      console.error(error);
      this.setState({
        loading: false,
        errorShow: true,
        errorMessage: 'Cannot connect to server, please try again later! 🙇🙇🙇'
      });
    });


    if (this.hasRecaptcha()){
      // Reset recaptcha token so it can be used again
      this.recaptcha.execute();
    }
  }

  hasRecaptcha() {
    return location.hostname !== "localhost";
  }

  toggleModal = () => {
    this.setState(prevState => ({
      openModal: !prevState.openModal
    }));
  };

  clearMessage = () => {
    const newMessage = [];

    this.props.change('message', newMessage);
    this.props.setMessage(newMessage);
    
    this.getFrontContent([]);
  };

  getFrontContent = message => {
    if (!message) {
      message = this.props.message;
    }

    this.props.loadingState(true);

    // Just blank out if empty, saving a server call
    if (message.length === 0) {
      this.setState({
        loading: false,
        emojiContent: '',
        errorShow: false
      });
      this.props.setFrontContent('');
      this.props.updateFrontContent('');
      this.props.loadingState(false);
      return;
    }

    const formattedMessage = message.length > 0 ? message.join(",") : '';

    let url = `/preview_v2?message=${formattedMessage}&preview=true`;

    if (formattedMessage === "1f600,2709-fe0f,1f602" ||
        formattedMessage === "1f600,2709-fe0f" ||
        formattedMessage === "1f600") {
      url = `/${formattedMessage}.html`;
    }

    fetch(url).then(response => {
      return response.text();
    }).then(html => {
      const parser = new DOMParser();
      const htmlDocument = parser.parseFromString(html, "text/html");
      const section = htmlDocument.documentElement.querySelector("#safe-area");
  
      this.setState({loading: false, emojiContent: section.innerHTML});
      this.props.updateFrontContent(section.innerHTML);
      this.props.setFrontContent(section.innerHTML);

      if (formattedMessage === "1f600,2709-fe0f,1f602") {
        this.props.successHtml(section.innerHTML);
      }
      this.props.loadingState(false);
    }).catch(() => {
      this.setState({loading: false});
      this.props.loadingState(false);
    });
    
  };

  undoMessage = () => {
    const newMessage = this.props.message;
    newMessage.pop();

    this.props.change('message', newMessage);
    this.props.setMessage(newMessage);

    this.getFrontContent();
  }

  errorShow = () => {
    return this.props.submitFailed || this.state.errorShow;
  }

  charactersLeft = () => {
    return Math.max(MAXIMUM_CHARACTERS - this.props.message.length, 0);
  }

  chooseEmoji = emoji => {
    const currentMessage = this.props.message;

    // Three rows (4 x 3)
    if (currentMessage.length >= MAXIMUM_CHARACTERS) {
      return;
    }

    currentMessage.push(emoji.unified);

    this.setState({
      loading: true,
    });

    this.props.change('message', currentMessage);
    this.props.setMessage(currentMessage);

    this.getFrontContent();
  };

  isLoading = () => {
    return this.state.loading || this.props.disableProgress();
  };

  canOpenModal = () => {
    return this.state.loading;
  };

  verifyCallback = (recaptchaToken) => {
    this.setState({
        recaptchaToken
    });
};

  render () {
    return (
      <React.Fragment>
        <div className="row">
          <GlobalAlert error={this.state.errorMessage} show={this.errorShow()} />
        </div>
        <div className="row">
          <div className="col-12 col-md-6 copy-column-vertical-center">
            <h2>Create your card</h2>
            <p>Use emoji keyboard below to write your postcard</p>
          </div>
          <div className="col-12 col-md-6">

            <div className="action-buttons-bar">
              <button onClick={this.clearMessage}>
                🆑
                <span className="sr-only">Clear</span>
              </button>
              <button onClick={this.undoMessage}>
                ↩️
                <span className="sr-only">Undo Last</span>
              </button>
            </div>

            <Picker
              set='apple'
              exclude={this.state.exclude}
              showSkinTones={true}
              title='Pick your emojis…'
              emoji='point_up'
              onClick={this.chooseEmoji}
              sheetSize={32}
              emojiSize={32}
            />
            {this.hasRecaptcha() &&
                <ReCaptcha
                    ref={ref => this.recaptcha = ref}
                    sitekey={GOOGLE_RECAPTCHA}
                    size="normal"
                    render="explicit"
                    verifyCallback={this.verifyCallback}
                />
            }
            <WizardButtonBar
              hasPrevious={true}
              previousClick={this.props.previousPage}
              nextLoading={this.state.loading}
              nextClick={this.checkMessage}
            />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

EmojiInput.propTypes = {
  change: PropTypes.func,
  handleSubmit: PropTypes.func,
  submitFailed: PropTypes.bool,
  error: PropTypes.string,
  submitting: PropTypes.bool,
  disableProgress: PropTypes.func,
  onSubmit: PropTypes.func,
  message: PropTypes.array,
  setMessage: PropTypes.func,
  frontContent: PropTypes.string,
  setFrontContent: PropTypes.func,
  previousPage: PropTypes.func,
  updateFrontContent: PropTypes.func,
  successHtml: PropTypes.func,
  loadingState: PropTypes.func,
  setMessageId: PropTypes.func,
  message_id: PropTypes.string
};

const mapStateToProps = state => {
  return {
      message: state.customReducer.message,
      frontContent: state.customReducer.frontContent,
      message_id: state.customReducer.message_id
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
      setMessage: (value) => {
          dispatch({
              type: "SET_MESSAGE",
              payload: value
          });
      },
      setMessageId: (id) => {
        dispatch({
          type: 'SET_MESSAGE_ID',
          payload: `${id}`
        });
      },
      setFrontContent: (value) => {
        dispatch({
          type: "SET_FRONT_CONTENT",
          payload: value
        });
      }
  };
};

export default reduxForm({
  form: 'wizard', // <------ same form name
  destroyOnUnmount: false, // <------ preserve form data
  forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
  validate,
  asyncBlurFields: []
})(connect(mapStateToProps, mapDispatchToProps)(EmojiInput))
