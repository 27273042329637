import React from 'react'
import { reduxForm, change } from 'redux-form'
import validate from '../helpers/validate'
import Address from './Address';
import { senderValidate } from '../helpers/addressValidate';
import GlobalAlert from './GlobalAlert';
import PropTypes from 'prop-types';
import WizardButtonBar from './WizardButtonBar';
import { connect } from 'react-redux';

class Sender extends React.Component {
  constructor(props) {
    super(props);
    this.getCountries = this.getCountries.bind(this);
    this.state = {
      loading: false
    }
  }

  getCountries = () => {
    return this.props.countries && this.props.countries.length ? this.props.countries : require('../helpers/countries.json').countries;
  }

  editAddress = () => {
    this.props.setSenderId('');
  };

  componentDidMount() {
    this.setState({
      loading: false
    })
  }

  handleSubmit = event => {
    // Set loading to true
    this.setState({
      loading: true
    });

    return this.props.handleSubmit(event).catch(() => {
      this.setState({
        loading: false
      })
    })
  };
  
  render () {
    const saved = this.props.sender_address_id.length > 0;
    return (
      <React.Fragment>
        <div className="row">
          <GlobalAlert error={this.props.error} show={this.props.submitFailed} />
        </div>
        <div className="row">
          <div className="col-12 col-md-6 address-copy">
            <h2>Fill in your details{saved && ": (Saved)"}</h2>
            <p className="half-width">Enter your postal details</p>
          </div>
          <div className="col-12 col-md-6">
            <form onSubmit={this.handleSubmit} className={this.state.loading ? 'loading' : ''}>
              <Address
                countries={this.getCountries}
                type="sender"
                title="From:"
                saved={saved}
                editAddress={this.editAddress}
                savedAddress={this.props.savedAddress}
                />
              <WizardButtonBar
                hasPrevious={true}
                previousClick={this.props.previousPage}
                previousLoading={this.props.previousPage}
                nextLoading={this.props.submitting}
              />
            </form>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

Sender.propTypes = {
  handleSubmit: PropTypes.func,
  countries: PropTypes.array,
  change: PropTypes.func,
  error: PropTypes.string,
  submitFailed: PropTypes.bool,
  submitting: PropTypes.bool,
  previousPage: PropTypes.func,
  dispatch: PropTypes.func,
  sender_address_id: PropTypes.string,
  setSenderId: PropTypes.func,
  savedAddress: PropTypes.object
};

const mapDispatchToProps = (dispatch) => {
  return {
    setSenderId: (value) => {
      dispatch({
        type: "SET_SENDER_ADDRESS_ID",
        payload: value
      });
      dispatch(change('wizard', 'sender_address_id', value));
    }
  }
};

const mapStateToProps = state => {
  return {
      message_id: state.form.wizard.values.message_id,
      sender_address_id: state.customReducer.sender_address_id,
      savedAddress: {
        name: state.form.wizard.values.sender_name,
        address_line1: state.form.wizard.values.sender_address_line1,
        address_line2: state.form.wizard.values.sender_address_line2,
        state: state.form.wizard.values.sender_state,
        city: state.form.wizard.values.sender_city,
        country: state.customReducer.sender_country,
        zip_code: state.form.wizard.values.sender_zip,
      }
  };
};

export default reduxForm({
  form: 'wizard', // <------ same form name
  destroyOnUnmount: false, // <------ preserve form data
  forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
  validate,
  asyncValidate: senderValidate,
  asyncBlurFields: []
})(connect(mapStateToProps, mapDispatchToProps)(Sender));
