import React, { Component } from 'react';
import Alert from 'react-bootstrap/Alert';
import PropTypes from 'prop-types';

class GlobalAlert extends Component {
  constructor(props) {
    super(props);
  }
  render () {
    return (
        <Alert key='global_error' variant='danger' show={this.props.show}>
            ⚠️ {this.props.error ? this.props.error : "Looks like you might not have filled in all the fields correctly!"}
        </Alert>
    );
  }
}

GlobalAlert.propTypes = {
  show: PropTypes.bool,
  error: PropTypes.string
};

export default GlobalAlert;