import React, { Component } from 'react';
import { Field } from 'redux-form';
import renderField from '../helpers/renderField';
import renderDropdownList from '../helpers/renderDropdownList';
import PropTypes from 'prop-types';

class Address extends Component {
    constructor(props) {
        super(props);
        this.handleSubmit = props.handleSubmit;
        this.changeProp = this.props.change;
    }

    getFieldName(field) {
        return `${this.props.type}_${field}`;
    }

    render() {
        if (!this.props.saved) {
            return(
                <React.Fragment>
                    <div className='lds lds-circle'><div></div></div>
                    <div className="row">
                        <div className="col-3 address-title">
                            <h3>
                                {this.props.title}
                            </h3>
                        </div>
                        <div className="col-9">
                            <Field
                                name={this.getFieldName('name')}
                                type="text"
                                component={renderField}
                                label="Name"
                                customLabel="To:"
                                className="name"
                                showLabel={true}
                            />
                        </div>
                    </div>
                    {(this.props.type == "sender") && 
                        <Field
                            name="email"
                            type="email"
                            component={renderField}
                            label="Email"
                            />}
                    <div className="hr"></div>
                    <h3>Address</h3>
                    <Field
                        name={this.getFieldName('address_line1')}
                        type="text"
                        component={renderField}
                        label="Address Line 1"
                    />
                    <Field
                        name={this.getFieldName('address_line2')}
                        type="text"
                        component={renderField}
                        label="Address Line 2"
                    />
                    <div className="row">
                        <div className="col-6">
                            <Field
                                name={this.getFieldName('city')}
                                type="text"
                                component={renderField}
                                label="City"
                            />
                        </div>
                        <div className="col-6">
                            <Field
                                name={this.getFieldName('zip')}
                                type="text"
                                component={renderField}
                                label="Zip/Post code"
                            />                        
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6">
                            <Field
                                name={this.getFieldName('state')}
                                type="text"
                                component={renderField}
                                label="State"
                            />
                        </div>
                        <div className="col-6">
                            <Field
                                name={this.getFieldName('country')}
                                component={renderDropdownList}
                                data={this.props.countries()}
                                label="Country"
                                valueField="country_code"
                                textField="display_name"
                            />
                        </div>
                    </div>
                </React.Fragment>
            );
        } else {
            return (
                <React.Fragment>
                    <div className="save-panel">
                        <div className="row">
                            <div className="col-12">
                                {this.props.savedAddress.name}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                {this.props.savedAddress.address_line1}
                            </div>
                        </div>
                        {
                            this.props.savedAddress.address_line2 && 
                            <div className="row">
                            
                        </div>
                        }
                        <div className="row">
                            <div className={this.props.savedAddress.zip_code ? "col-6" : "col-12"}>
                                {this.props.savedAddress.city}
                            </div>
                            {
                                this.props.savedAddress.zip_code && 
                                <div className="col-6">
                                    {this.props.savedAddress.zip_code}
                                </div>
                            }
                        </div>
                        <div className="row">
                            {
                                this.props.savedAddress.state &&
                                <div className="col-4">
                                    {this.props.savedAddress.state}
                                </div>
                            }
                            <div className={this.props.savedAddress.state ? "col-4" : "col-8"}>
                                {this.props.savedAddress.country}
                            </div>
                            <div className={this.props.savedAddress.state ? "col-4" : "col-6"}>
                                <button onClick={this.props.editAddress}>
                                    Edit
                                </button>
                            </div>
                        </div>
                    </div>
                </React.Fragment>
            );
        }
    }
}

Address.propTypes = {
    handleSubmit: PropTypes.func,
    countries: PropTypes.func,
    updateCountry: PropTypes.func,
    change: PropTypes.func,
    type: PropTypes.string,
    title: PropTypes.string,
    saved: PropTypes.bool,
    editAddress: PropTypes.func,
    savedAddress: PropTypes.object
};

export default Address;