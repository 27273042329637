import React, { Component } from "react";
import DropIn from "braintree-web-drop-in-react";
import { connect } from 'react-redux';
import { getFormData } from '../helpers/form';
import GlobalAlert from './GlobalAlert';
import WizardButtonBar from './WizardButtonBar';
import PropTypes from 'prop-types';

class Checkout extends Component {
    constructor(props) {
        super(props);
        this.state = {
            clientToken: null,
            showPayButton: false,
            showError: false,
            loading: true,
            loadingFilter: false
        }
    }

    async componentDidMount() {
        // Get a client token for authorization from your server
        const response = await fetch("/client_token.json");
        const clientToken = (await response.json()).token; // If returned as JSON string

        this.setState(() => ({
            clientToken,
            showPayButton: true,
            loading: false
        }));

        setTimeout(() => {
            this.setState({
                loadingFilter: true
            });
        }, 3000);
    }

    buy = () => {
        const props = this.props;
        const handleSubmit = props.onSubmit;
        const self = this;

        this.setState({
            nextLoading: true
        });

        this.instance.requestPaymentMethod().then((response) => {
            const payload = {
                payment_method_nonce: response.nonce,
                message_id: props.message_id,
            };

            self.setState({
                showError: false
            });

            fetch(`/checkout/`, {
                method: 'post',
                headers: {
                    'X-CSRF-Token': document.querySelector('[name="csrf-token"]').content
                  },
                  body: getFormData(payload),
              }).then((response) => {
                  if (response.ok) {
                        self.setState({
                            nextLoading: false
                        });
                      handleSubmit();
                  } else {
                      self.setState({
                          showError: true,
                          error: "Error processing payment, please retry again later.",
                          nextLoading: false
                      });
                  }
              }, (error) => {
                  console.log(error);
                  self.setState({
                      showError: true,
                      error: "Error processing payment, please retry again later.",
                      nextLoading: true
                  });
              });
        }).catch(() => {
            this.setState({
                nextLoading: false
            });    
        });
    }

    render() {
        return (
            <React.Fragment>
                <div className="row">
                    <GlobalAlert error={this.state.error} show={this.state.showError} />
                </div>
                <div className="row checkout-copy">
                    <div className="col-12">
                        <h2>Summary</h2>
                        <p className="half-width"><strong>Note:</strong> Postage takes roughly 2 weeks.</p>
                        <p className="half-width">Enter your payment details to send your EmojiMail</p>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 col-md-6 address-summary">
                        <small>Name</small>
                        <p>{this.props.receiver.name}</p>

                        <small>Address{this.props.receiver.address_line2 && " Line 1"}</small>
                        <p>{this.props.receiver.address_line1}</p>

                        {this.props.receiver.address_line2 && 
                            <React.Fragment>
                                <small>Address Line 2</small>
                                <p>{this.props.receiver.address_line2}</p>
                            </React.Fragment>
                        }

                        <div className="row">
                            <div className={`${this.props.receiver.zip_code ? 'col-6' : 'col-6'}`}>
                                <small>City</small>
                                <p>{this.props.receiver.city}</p>
                            </div>
                            {this.props.receiver.zip_code &&
                                <div className="col-4">
                                    <small>Zip/Post Code</small>
                                    <p>{this.props.receiver.zip_code}</p>
                                </div>
                            }
                            {!this.props.receiver.zip_code &&
                                <div className="col-6">
                                    <small>Country</small>
                                    <p>{this.props.receiver.country}</p>
                                </div>
                            }
                        </div>
                        {this.props.receiver.zip_code &&
                            <React.Fragment>
                                <small>Country</small>
                                <p>{this.props.receiver.country}</p>
                            </React.Fragment>
                        }
                        <div className="price">
                            Total: <span>{this.props.price}</span>
                        </div>
                    </div>
                    <div className={`col-12 col-md-6`}>
                        <div className={`payment-box ${this.state.loadingFilter ? '' : 'loading'}`}>
                            <div className="lds lds-circle"><div></div></div>
                            {!this.state.loading && <DropIn
                                options={{ authorization: this.state.clientToken }}
                                onInstance={instance => (this.instance = instance)}
                            />}
                        </div>
                        <WizardButtonBar
                            hasPrevious={true}
                            nextClick={this.buy}
                            previousLoading={this.state.nextLoading}
                            nextLoading={this.state.loading || this.state.nextLoading}
                            previousClick={this.props.previousPage}
                            nextCopy="Pay & Send!"
                        />
                    </div>
                </div>
            </React.Fragment>);
    }
}

Checkout.propTypes = {
    previousPage: PropTypes.func,
    onSubmit: PropTypes.func,
    message_id: PropTypes.string,
    sender: {
        name:  PropTypes.string,
        address_line1:  PropTypes.string,
        address_line2:  PropTypes.string,
        zip_code:  PropTypes.string,
        city:  PropTypes.string,
        state:  PropTypes.string,
        country:  PropTypes.string
    },
    receiver: {
        name:  PropTypes.string,
        address_line1:  PropTypes.string,
        address_line2:  PropTypes.string,
        zip_code:  PropTypes.string,
        city:  PropTypes.string,
        state:  PropTypes.string,
        country:  PropTypes.string
    },
    price: PropTypes.string
};

const mapStateToProps = state => {
    return {
        message_id: state.form.wizard.values.message_id,
        receiver: {
            name: state.form.wizard.values.receiver_name,
            address_line1: state.form.wizard.values.receiver_address_line1,
            address_line2: state.form.wizard.values.receiver_address_line2 ? state.form.wizard.values.receiver_address_line2 : '',
            zip_code: state.form.wizard.values.receiver_zip,
            city: state.form.wizard.values.receiver_city,
            state: state.form.wizard.values.receiver_state ? state.form.wizard.values.receiver_state : '',
            country: state.customReducer.receiver_country,
        },
        sender: {
            name: state.form.wizard.values.sender_name,
            address_line1: state.form.wizard.values.sender_address_line1,
            address_line2: state.form.wizard.values.sender_address_line2 ? state.form.wizard.values.sender_address_line2 : '',
            zip_code: state.form.wizard.values.sender_zip,
            city: state.form.wizard.values.sender_city,
            state: state.form.wizard.values.sender_state ? state.form.wizard.values.sender_state : '',
            country: state.customReducer.sender_country,
        },
        price: state.customReducer.checkoutPrice
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setNonce: (value) => {
            dispatch({
                type: "SET_PAYMENT_NONCE",
                payload: value
            })
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Checkout);
