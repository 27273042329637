import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import PropTypes from 'prop-types';

class CostModal extends Component {
    render() {
        return (
            <Modal isOpen={this.props.openModal} toggle={this.props.toggleModal}>
                <ModalHeader toggle={this.props.toggleModal}>🌏 Pricing</ModalHeader>
                <ModalBody>
                    <p>
                        {this.getCTACopy()}
                    </p>
                    <p className="callout">
                        <strong>New countries being added all the time!</strong>
                    </p>
                    <table className="table table-borderless">
                        <thead>
                            <tr>
                                <th scope="col">
                                    Country
                                </th>
                                <th scope="col">
                                    Cost
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.createTable()}
                        </tbody>
                    </table>
                </ModalBody>
                <ModalFooter>
                    <button className="btn btn-primary" onClick={this.props.toggleModal}>Done</button>
                </ModalFooter>
            </Modal>
        )
    }

    createTable = () => {
        let table = [];
        const defaultCountryCode = this.props.country.country_code;
        this.props.countries.forEach(country => {
            let row = [];
            row.push(<td key={country.country_code + '-name'}>{country.flag} {country.name}</td>);
            row.push(<td key={country.country_code + '-price'}><strong>{country.currency_symbol}{country.price} ({country.currency_code})</strong></td>);

            if (country.country_code === defaultCountryCode) {
                table.push(<tr key={country.country_code} className="table-active">{row}</tr>);
            } else {
                table.push(<tr key={country.country_code}>{row}</tr>);
            }
        });

        return table;
    };

    getCTACopy = () => {
        if (this.props.countryDetected) {
            return `We detected you are in ${this.props.country.name} ${this.props.country.flag}, however you can send EmojiMailer to your friends all around the world!`;
        } else {
            return `Send your friends all around the world EmojiMail!`;
        }
    };
}

CostModal.propTypes = {
    toggleModal: PropTypes.func,
    openModal: PropTypes.bool,
    countries: PropTypes.array,
    country: PropTypes.object,
    countryDetected: PropTypes.bool
};

export default CostModal;