import React, { Component } from 'react'
import { Provider } from "react-redux";
import ReactGA from 'react-ga';
import { loadReCaptcha } from 'react-recaptcha-v3';

import EmojiInput from '../components/EmojiInput'
import Receiver from '../components/Receiver'
import Sender from '../components/Sender'
import store from "../store";
import Success from '../components/Success';
import Checkout from '../components/Checkout';
import LandingTop from '../components/LandingTop';
import LandingBottom from '../components/LandingBottom';
import PreviewPanel from '../components/PreviewPanel';
import Footer from '../components/Footer';
import { GOOGLE_ANALYTICS, GOOGLE_RECAPTCHA } from '../helpers/keys';

ReactGA.initialize(GOOGLE_ANALYTICS, {
  debug: window.location.hostname === "localhost"
});

class WizardForm extends Component {
  constructor(props) {
    super(props)

    this.state = {
      page: 1,
      frontContent: '',
      defaultCountry: {},
      receiverCountries: [],
      countryDetected: false,
      openAboutModal: false,
      openContactModal: false,
      message: [],
      includeBackContent: true,
      emojiLoading: false,
      successHtml: '',
      countriesLoading: true
    }

    WizardForm.setParentClass(this.state.page);

    //TODO: Move to component mount
    fetch('/default_country').then((data) => {
      return data.json();
    }).then((json) => {
      this.setState({
        defaultCountry: json
      });

      fetch('/receiver_countries').then((data) => {
        return data.json();
      }).then((countries) => {
        this.setState({
          receiverCountries: countries
        });
  
        fetch('https://ipapi.co/json/').then((data) => {
          return data.json();
        }).then((ipCountry) => {
          countries.forEach(country => {
            // If the country is in the receiver list, then update
            if (country.country_code === ipCountry.country) {
              this.setState({
                defaultCountry: country,
                countryDetected: true,
              });
            }

            this.setState({
              countriesLoading: false
            });
          });
        }).catch(error => {
          console.log(error);
          this.setState({
            countriesLoading: false
          });
        });
      });
  
      fetch('/sender_countries').then((data) => {
        return data.json();
      }).then((json) => {
        this.setState({
          senderCountries: json
        });
      });
    });

    this.disablePreviewProgress = this.disablePreviewProgress.bind(this);
    ReactGA.pageview("/landing");
  }

  setEmojiLoadingState = (emojiLoading) => {
    this.setState({
      emojiLoading
    })
  };

  setSuccessHtml = (successHtml) => {
    this.setState({
      successHtml
    })
  };

  getFrontContent = () => {
    return this.state.page === 6 ? this.state.successHtml : this.state.frontContent;
  }

  getIncludeBackContent = () => {
    return !(this.state.page === 6);
  }

  updateBackContentState = () => {
    if (this.state.page === 6) {
      this.setState({
        includeBackContent: false
      });
    } else {
      this.setState({
        includeBackContent: true
      });
    }
  }
  
  nextPage = () => {
    var page = this.state.page + 1;

    WizardForm.setParentClass(page);

    this.updateBackContentState();

    ReactGA.pageview(`/${WizardForm.PAGES[page]}`);

    return new Promise(resolve => {
        this.setState({
          page
        });
        resolve(true);
    });
  }

  static setParentClass(page) {
    if (page !== 1) {
      document.body.classList.add('progressed');
    } else {
    document.body.classList.remove('progressed');
    }
  }

  static PAGES = {
    1: "landing",
    2: "emoji_keyboard",
    3: "receiver",
    4: "sender",
    5: "checkout",
    6: "success"
  };

  previousPage = () => {
    var page = this.state.page - 1;

    WizardForm.setParentClass(page);

    this.updateBackContentState();

    this.setState({ page });

    ReactGA.pageview(`/${WizardForm.PAGES[page]}`);
  }
  
  startOver = () => {
    WizardForm.setParentClass(2);
    this.setState({
      frontContent: ''
    });
    this.updateBackContentState();
    this.setState({page: 2});
    ReactGA.pageview(`/${WizardForm.PAGES[2]}`);
  }

  disablePreviewProgress() {
    return !((this.state.senderCountries && this.state.senderCountries.length) &&
      (this.state.receiverCountries && this.state.receiverCountries.length));
  }

  updateFrontContent = (frontContent) => {
    this.setState({
      frontContent
    });
  }

  hasRecaptcha() {
    return location.hostname !== "localhost";
  }

  componentDidMount() {
    if (this.hasRecaptcha()) {
      loadReCaptcha(GOOGLE_RECAPTCHA);
    }
  }

  render() {
    const { page } = this.state;

    return (
      <Provider store={store}>
        <div id="topComponent" className="row">
          {page === 1 && (
            <LandingTop />
          )}
          {page !== 1 && (
            <PreviewPanel
              frontContent={this.getFrontContent()}
              includeBackContent={this.getIncludeBackContent()}
              loading={this.state.emojiLoading}
            />
          )}
        </div>
        <div id="bottomComponent" className="row" page={page}>
          <div className="container">
            {page === 1 && (
              <LandingBottom
                onSubmit={this.nextPage}
                country={this.state.defaultCountry}
                countries={this.state.receiverCountries}
                countryDetected={this.state.countryDetected}
                countriesLoading={this.state.countriesLoading}
              />
            )}
            {page === 2 && (
              <EmojiInput
                previousPage={this.previousPage}
                onSubmit={this.nextPage}
                updateFrontContent={this.updateFrontContent}
                disableProgress={this.disablePreviewProgress}
                loadingState={this.setEmojiLoadingState}
                successHtml={this.setSuccessHtml}
              />
            )}
            {page === 3 && (
              <Receiver
                previousPage={this.previousPage}
                onSubmit={this.nextPage}
                countries={this.state.receiverCountries}
              />
            )}
            {page === 4 && (
              <Sender
                previousPage={this.previousPage}
                onSubmit={this.nextPage}
                countries={this.state.senderCountries}
              />
            )}
            {page === 5 && (
              <Checkout
                previousPage={this.previousPage}
                onSubmit={this.nextPage}
              />
            )}
            {page === 6 && (
              <Success
                previousPage={this.previousPage}
                onSubmit={this.startOver}
              />
            )}
          </div>
        </div>
        <Footer countries={this.state.receiverCountries} countriesLoading={this.state.countriesLoading}/>
      </Provider>
    )
  }
}

WizardForm.propTypes = {

};


export default WizardForm;
