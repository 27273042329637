import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';

const getOptions = (options, value, text) => {
  let row = [];
  options.forEach(option => {
    row.push(<option value={option[value]} key={option[value]}>{option[text]}</option>)
  });
  return row;
};

const renderDropdownList = ({ input, data, label, valueField, textField, meta: { touched, error } }) =>
    (<div className="form-group">
      <label htmlFor={input.name} className="sr-only">{label}</label>
      <div className="select-wrapper">
        <Field className="form-control" name={input.name} component="select">
          <option value="" key="">{label}</option>
          {getOptions(data, valueField, textField)}
        </Field>
      </div>
      {touched && error && <div className="invalid-feedback" style={{display: "block"}}>{error}</div>}
    </div>);

renderDropdownList.propTypes = {
  input: PropTypes.object,
  data: PropTypes.array,
  label: PropTypes.string,
  valueField: PropTypes.string,
  textField: PropTypes.string,
  meta: PropTypes.object,
  updateCountry: PropTypes.func
};

export default renderDropdownList;