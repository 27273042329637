import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import PropTypes from 'prop-types';

class AboutModal extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <Modal isOpen={this.props.openModal}  toggle={this.props.toggleModal}>
                <ModalHeader toggle={this.props.toggleModal}>🤷 About</ModalHeader>
                <ModalBody>
                    <p>
                        EmojiMailer brings joy to you and your friends by allowing you to send postcards with emojis all over the world!
                    </p>
                    {!this.props.countriesLoading &&
                        <React.Fragment>
                            <p>Currently we send to {this.props.countries.length} countries!</p>
                            <ul className="country-list">
                                {this.getCountryList() }
                                <li key="more"><span className="more-countries"></span>With more coming all the time!</li>
                            </ul>
                        </React.Fragment>
                    }
                    <p>Want to learn more? Feel free to <a href="#" onClick={this.props.goToContact}>reach out to us!</a></p>
                </ModalBody>
                <ModalFooter>
                    <button className="btn btn-primary" onClick={this.props.toggleModal}>Done</button>
                </ModalFooter>
            </Modal>
        )
    }

    getCountryList = () => {
        let rows = [];
        this.props.countries.forEach(country => {
            rows.push(<li key={country.country_code}>{country.flag} {country.name}</li>);
        });

        return rows;
    }
}

AboutModal.propTypes = {
    toggleModal: PropTypes.func,
    openModal: PropTypes.bool,
    countries: PropTypes.array,
    goToContact: PropTypes.func,
    countriesLoading: PropTypes.bool
};

export default AboutModal;
