import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';

class WizardButtonBar extends Component {
  constructor(props) {
    super(props);
  }

  render () {
    const previousCopy = this.props.previousCopy && this.props.previousCopy.length > 0 ? this.props.previousCopy : "Back";
    const nextCopy = this.props.nextCopy && this.props.nextCopy.length > 0 ? this.props.nextCopy : "Next";

    return (
        <div className="row button-bar">
            <div className="col-6">
                { this.props.hasPrevious && 
                  <Button
                      onClick={this.props.previousClick ? this.props.previousClick : () => {}}
                      variant="secondary"
                      className={"back" + (this.props.nextLoading ? " loading": "")}
                      size="lg">
                    <div className="lds lds-ellipsis"><div></div><div></div><div></div><div></div></div>
                    {previousCopy}
                  </Button>
                }
            </div>
            <div className="col-6">
                <Button
                    onClick={this.props.nextClick ? this.props.nextClick : () => {}}
                    variant="primary"
                    size="lg"
                    className={"next" + (this.props.nextLoading ? " loading": "")}
                    type="submit">
                    <div className="lds lds-ellipsis"><div></div><div></div><div></div><div></div></div>
                  {nextCopy}
                </Button>
            </div>
        </div>
    );
  }
}

WizardButtonBar.propTypes = {
  hasPrevious: PropTypes.bool,
  previousCopy: PropTypes.string,
  previousClick: PropTypes.func,
  nextClick: PropTypes.func,
  nextCopy: PropTypes.string,
  nextLoading: PropTypes.bool,
  nextDisabled: PropTypes.bool,
};

export default WizardButtonBar;
