import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactResizeDetector from 'react-resize-detector';

class PreviewPanel extends Component {

    constructor(props) {
        super(props);
        
        this.state = {
            front: true,
            backContentLoading: true,
            backContent: ""
        };

        // If not generated yet
        if (this.props.includeBackContent) {
            this.getBackContent();
        }

        this.frontCardRef = React.createRef();
        if (this.props.includeBackContent){
            this.backCardRef = React.createRef();
        }
        this.parentCardRef = React.createRef();

        this.onResize = this.onResize.bind(this);
    }

    spinCard = () => {
        this.setState({
            front: !this.state.front
        });
    };
    
    getBackContent = () => {
        fetch('/back_content.html').then(response => {
            return response.text();
        }).then(html => {
            const parser = new DOMParser();
            const htmlDocument = parser.parseFromString(html, "text/html");
            const section = htmlDocument.documentElement.querySelector("#safe-area");
            this.setState({
                backContent: section.innerHTML,
                backContentLoading: false
            })
        });
    };

    frontCard = () => {
        this.setState({
            front: true
        });
    };

    backCard = () => {
        this.setState({
            front: false
        });
    };

    onResize(outsideWidth) {
        const insideWidth = this.frontCardRef.current.clientWidth;

        const scale = outsideWidth / insideWidth;

        const transform = `translate(-50%, -50%) scale(${scale})`;

        this.frontCardRef.current.style.transform = transform;

        if (this.props.includeBackContent){
            this.backCardRef.current.style.transform = transform;
        }
    }

    render() {
        const isLoading = (this.props.loading && this.state.front) || (this.state.backContentLoading && !this.state.front);
        return(
            <React.Fragment>
                <div className="container">
                    <div className="content">
                        <div className={`preview-panel ${isLoading ? 'loading' : ''}`}>
                            <ReactResizeDetector handleWidth handleHeight onResize={this.onResize} />
                            <div className={`embed-container front-preview ${this.state.front ? '' : 'hidden'}`}>
                                <div id="front-preview-message" className={`preview-message`}>
                                    <div id="safe-area" dangerouslySetInnerHTML={{ __html: this.props.frontContent === "LOADING" ? '' : this.props.frontContent }} ref={this.frontCardRef}></div>
                                </div>
                            </div>
                            {this.props.includeBackContent && 
                                <div className={`embed-container back-preview ${this.state.front ? 'hidden' : ''}`}>
                                    <div id="back-preview-message" className="preview-message">
                                        <div id="safe-area" dangerouslySetInnerHTML={{ __html: this.state.backContent }} ref={this.backCardRef}></div>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
                {this.props.includeBackContent && 
                    <div className="preview-button-bar">
                        <div className="button-group">
                            <button onClick={this.frontCard} className={`preview-tab ${this.state.front ? 'active' : ''}`}>
                                <span className="sr-only">
                                    Left
                                </span>
                            </button>
                            <button onClick={this.backCard} className={`preview-tab ${this.state.front ? '' : 'active'}`}>
                                <span className="sr-only">
                                    Right
                                </span>
                            </button>
                            <button onClick={this.spinCard} className="preview-swap">
                                <img src="/assets/swap.svg"/>
                                <span className="sr-only">
                                    Swap
                                </span>
                            </button>
                        </div>
                    </div>
                }
            </React.Fragment>
        );
    }
}

PreviewPanel.propTypes = {
    frontContent: PropTypes.string,
    includeBackContent: PropTypes.bool,
    loading: PropTypes.bool
};


export default PreviewPanel;